$(document).ready(function () {
  var hiddenClass = 'hidden';
  var activeClass = 'active';
  var highlightReg = /<span class="highlight">(.*?)<\/span>/g;
  var highlightHtml = '<span class="highlight">$1</span>';

  var $searchInput = $('.js-input-search');
  var $clearText = $('.js-clear-text');
  var $formSearch = $('form[name="formFAQSearch"]');
  var $category = $('.js-faqs .js-category');
  var $categories = $('.js-faqs .js-categories');
  var $questions = $('.js-faqs .js-header-collapse');
  var $errNoResult = $('.js-faqs .js-err-no-results');
  var $errFewLetters = $('.js-faqs .js-err-few-letters');
  var $faqsContent = $('.js-faqs .js-faqs-content');
  var $panelCollapse = $('.js-faqs .js-panel-collapse');
  var $tabActive  =$('.js-faqs a[data-toggle="tab"]');

  $searchInput.on('input', function () {
    var inputValue = $(this).val();

    if (inputValue.length) {
      $clearText.removeClass(hiddenClass);
    } else {
      $clearText.addClass(hiddenClass);
    }
  });

  $clearText.click(function () {
    $searchInput.val('').focus();

    $(this).addClass(hiddenClass);

    $formSearch.submit();
  });

  $formSearch.on('submit', function () {
    var searchValue = $(this).find($searchInput).val().toLowerCase();
    var length = searchValue.length;

    if (!length) {
      activeDefault();
    }

    if (length && length < 2) {
      $category.removeClass(activeClass);

      $faqsContent.addClass(hiddenClass);
      $errFewLetters.removeClass(hiddenClass);

      return;
    }

    if ($questions.text().toLowerCase().indexOf(searchValue) > -1) {
      $category.addClass(hiddenClass);

      $faqsContent.removeClass(hiddenClass);
      $errNoResult.addClass(hiddenClass);
      $errFewLetters.addClass(hiddenClass);

      if (window.onMobileScreen()) {
        $categories.removeClass(hiddenClass);
      }
    } else {
      $category.removeClass(hiddenClass);
      $category.removeClass(activeClass);

      $faqsContent.addClass(hiddenClass);

      if (window.onMobileScreen()) {
        $categories.addClass(hiddenClass);
      }
      $errNoResult.removeClass(hiddenClass);
    }

    $questions.each(function (index, title) {
      title = $(title);

      if (title.text().toLowerCase().indexOf(searchValue) > -1) {
        var category = title.data('category-id');
        var target = $categories.find('[data-question-id="' + category + '"]');
        $(target).removeClass(hiddenClass);

        title.removeClass(hiddenClass);
      } else {
        $panelCollapse.collapse('hide');

        title.addClass(hiddenClass);
      }

      var text = title.html().replace(highlightReg, '$1');
      text = text.replace(new RegExp('(' + searchValue + ')', 'gi'), highlightHtml);

      title.html(text);
    });
  });

  $panelCollapse.on('shown.bs.collapse', function () {
    var $panel = $(this).closest('.js-panel');

    window.scrollToElement($panel);
  });

  $tabActive.on('shown.bs.tab', function (e) {

    var target = $(e.target).attr('href'); // activated tab

    $panelCollapse.collapse('hide');

    if (!onMobileScreen()) {
      window.scrollToElement($(target));
    }
  });

  $questions.on('click', function (event, ignoreHistory) {
    if (ignoreHistory) {
      return;
    }

    var identify = $(this).data('identify');
    if (!identify) {
      return;
    }

    var hash = '#question-' + identify;
    var previous = {
      'previousHash': identify
    };

    window.updateHistory(hash, previous);
  });

  $category.on('click', function (event, ignoreHistory) {
    if (window.onMobileScreen()) {
      window.scrollToElement($(this));
    }

    if (ignoreHistory) {
      return;
    }

    var identify = $(this).data('category-identify');
    if (!identify) {
      return;
    }

    var query = '?category=' + identify;
    var previous = {
      'previousQuery': identify
    };

    window.updateHistory(query, previous);
  });

  window.addEventListener('popstate', function (event) {
    if (!event.state) {
      return;
    }

    var categoryIdentify = event.state.previousQuery;
    activeCategory(categoryIdentify);

    var questionIdentify = event.state.previousHash;
    activeQuestion(questionIdentify);
  });

  function activeCategory(identify) {
    var category = $categories.find('[data-category-identify="' + identify + '"]');
    if (category.length) {
      $(category).find('a').trigger('click', [true]);
    }
  }

  function activeQuestion(identify) {
    var question = $faqsContent.find('[data-identify="' + identify + '"]');
    if (question.length) {
      question.trigger('click', [true]);
    }
  }

  function activeDefault() {
    var location = window.location;
    var firstIdentify = $category.first().data('category-identify');
    if (!location.search && !location.hash && firstIdentify) {
      var query = '?category=' + firstIdentify;
      var previous = {
        'previousQuery': firstIdentify
      };

      window.updateHistory(query, previous);
    }

    var categoryIdentify = location.search.split('=').pop();
    if (location.search && categoryIdentify) {
      activeCategory(categoryIdentify);
    }

    // var questionIdentify = location.hash.split('-').pop();
    // if (location.hash && questionIdentify) {
    //   activeQuestion(questionIdentify);
    // }
  }

  activeDefault();
});
